import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { memo, useEffect, useState } from "react";

import { AMCReportType } from "~/typedef/amc/reportType";
import { AmcReportConfigForm } from "./scheduleConfigForm";
import { Grid } from "@material-ui/core";
import PageBlock from "~/components/containers/sideNavPageBlock";
import get from "lodash/get";
import moment from "moment-timezone";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 5;

const CreateReport = memo(function CreateReport({}) {
  const user = useTypedSelector((state) => get(state, "user"));
  const { currentPeriod, selectedTimezone, currentCompare, currentRange } =
    useDateRangeFilters();
  const currentCurrency = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentCurrency")
  );
  const includeTaxDefault = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const preferredTimezone = useTypedSelector(
    (state) => state?.persistentAppSettings?.setting?.data?.preferredTimezone
  );
  const store = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );

  const [name, setName] = useState("");
  const [queryType, setQueryType] = useState<AMCReportType | null>(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [includeTax, setIncludeTax] = useState(includeTaxDefault);
  const [period, setPeriod] = useState(currentPeriod);
  const [comparisonPeriod, setComparisonPeriod] = useState(currentCompare);
  const [fromDate, setFromDate] = useState(currentRange.fromDate); // default to current range
  const [toDate, setToDate] = useState(currentRange.toDate);
  const [priorFromDate, setPriorFromDate] = useState(
    currentRange.priorFromDate
  ); // default to current range
  const [priorToDate, setPriorToDate] = useState(currentRange.priorToDate);
  const [interval, setInterval] = useState(currentRange.interval);
  const [currency, setCurrency] = useState<string>(currentCurrency ?? "USD");
  const [emailsToBeSent, setEmailsToBeSent] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");

  const [timezone, setTimezone] = useState(selectedTimezone);
  const [frequency, setFrequency] = useState<string | number>("once"); // once off
  const [reportingDelay, setReportingDelay] = useState<number | undefined>(0); // in days

  const setNonStoreStateDefaults = () => {
    setName("");
    setPeriod(currentPeriod);
    setComparisonPeriod(currentCompare);
    setPageSize(PAGE_SIZE);
    setFromDate(currentRange.fromDate); // default to current range
    setToDate(currentRange.toDate);
    setPriorFromDate(currentRange.priorFromDate);
    setPriorToDate(currentRange.priorToDate);
    setInterval(currentRange.interval);
    setTimezone(selectedTimezone);
    if (currentCurrency) {
      setCurrency(currentCurrency);
    }
    setIncludeTax(includeTaxDefault);
    setFrequency("once"); // once off
    setReportingDelay(undefined);
  };

  useEffect(() => {
    setNonStoreStateDefaults();
  }, []);

  useEffect(() => {
    if (user?.email) {
      setEmailsToBeSent([user.email]);
    }
  }, []);

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <AmcReportConfigForm
          {...{
            setQueryType,
            setFrequency,
            setReportingDelay,
            setFromDate,
            setToDate,
            setPriorFromDate,
            setPriorToDate,
            setInterval,
            setName,
            setEmailsToBeSent,
            setDescription,
            setPeriod,
            setComparisonPeriod,
            setPageSize,
            setIncludeTax,
            timezone,
            setTimezone,
            preferredTimezone,
            setCurrency,
            currency,
            includeTax,
            period,
            name,
            description,
            emailsToBeSent,
            queryType,
            comparisonPeriod,
            pageSize,
            fromDate,
            toDate,
            priorFromDate,
            priorToDate,
            interval,
            frequency,
            reportingDelay,
            store,
          }}
        />
      </Grid>
    </PageBlock>
  );
});

export default CreateReport;
