import {
  ButtonBase,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { memo, useMemo } from "react";

import { ArrowDropDown } from "@material-ui/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MultiSelectBase = styled(ButtonBase)<{
  $width?: string;
  $height?: string;
}>`
  padding: 0 0.5rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.disabled.light : theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const MultiSelectMenuItem = styled(MenuItem)<{ $width?: number | null }>`
  ${({ $width }) => ($width ? `width: ${$width}px;` : null)}
`;

type Option = {
  value: any;
  label: string;
};

interface MultiSelectProps {
  label?: string;
  currentValues: any[];
  handleChange: (values: any[]) => void;
  options: Option[];
  width?: string;
  height?: string;
  disabled?: boolean;
}

const MultiSelect = memo<MultiSelectProps>(function MultiSelect({
  label,
  options,
  currentValues,
  handleChange,
  width,
  height,
  disabled,
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [menuWidth, setMenuWidth] = React.useState(
    anchorEl ? anchorEl.offsetWidth : null
  );
  const { t } = useTranslation();

  const selectedLabels = useMemo(() => {
    return options
      .filter((option) => currentValues.includes(option.value))
      .map((option) => option.label);
  }, [currentValues, options]);

  React.useEffect(() => {
    setMenuWidth(anchorEl ? anchorEl.offsetWidth : null);
  }, [anchorEl]);

  const openMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClick = (value: any, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    const newValues = currentValues.includes(value)
      ? currentValues.filter((v) => v !== value)
      : [...currentValues, value];
    handleChange(newValues);
  };

  return (
    <Grid container item xs={12} direction="column">
      <MultiSelectBase
        id="multi-select"
        onClick={openMenu}
        $width={width}
        $height={height}
        disabled={disabled}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography>
            {label
              ? t(label)
              : selectedLabels.length > 0
              ? selectedLabels.join(", ")
              : t("connectWizard.multiSelectLabel")}
          </Typography>
        </Grid>
        <Grid item>
          <ArrowDropDown />
        </Grid>
      </MultiSelectBase>
      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        autoFocus={false}
        marginThreshold={1}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option) => (
          <MultiSelectMenuItem
            key={option.value}
            $width={menuWidth}
            onClick={() => handleClick(option.value)}
          >
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Checkbox
                  checked={currentValues.includes(option.value)}
                  size="small"
                />
                {option.label}
              </Grid>
            </Grid>
          </MultiSelectMenuItem>
        ))}
      </Menu>
    </Grid>
  );
});

export default MultiSelect;
