import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { ENTITY_TYPE, getPerformanceColumns } from "./commonColumns";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import { useReportDateFrom, useReportDateTo } from "./useReportDate";

import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import Panel from "~/components/panel/panel";
import SearchFilter from "~/components/adTable/searchFilter";
import StatusCell from "./statusCell";
import Table from "~/components/adTable/table";
import { fetchMarketingProductAds } from "../../store/mystore/marketing.redux";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { usePersistentTableColumns } from "~/utils/usePersistentTableColumns";
import { PERSISTENT_TABLE_IDS } from "~/utils/persistentTableIdsEnum";

const PAGE_SIZE = 10;

interface ProductAdSummaryPanel {
  mid: string;
  countryCode: string;
  marketplaceType: string;
  adGroupId?: string;
}

const ProductAdSummaryPanel = memo<ProductAdSummaryPanel>(
  function ProductAdSummaryPanel({
    mid,
    countryCode,
    marketplaceType,
    adGroupId,
  }) {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);
    const store = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentStore")
    );
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useTypedSelector((state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency")
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[store?.marketplaceCountry || ""],
      currentCurrency
    );
    const marketing = useTypedSelector((state) => get(state, "marketing"));
    const loading = useTypedSelector((state) =>
      get(state, "marketing.productAds.loading")
    );

    const reportDateFrom = useReportDateFrom();
    const reportDateTo = useReportDateTo();

    const isTikTokMarketplace = marketplaceType === "tiktok";

    const dispatch = useDispatch();

    const getInitialColumns = useCallback(
      () => [
        ...(!adGroupId
          ? [
              {
                Header: t("advertisingDashboardWidget.adTable.campaignColumn"),
                id: "campaign_name",
                accessor: "campaign_name",
                customWidth: 150,
                isVisible: true,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.adGroupColumn"),
                id: "ad_group_name",
                accessor: "ad_group_name",
                isVisible: true,
              },
            ]
          : []),
        {
          Header: t("advertisingDashboardWidget.adTable.productColumn"),
          id: "product_title",
          accessor: (row: any) => ({
            value: row.product_title,
            secondRowValue: `${
              marketplaceType?.startsWith("amazon") ? "ASIN" : "SKU"
            }: ${row.asin}`,
            image: row.image_url,
            link: row.link_url,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell
              {...props}
              colorVariant="external"
              maxWidth="25rem"
            />
          ),
          isVisible: true,
          disableSortBy: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.statusColumn"),
          id: "product_ad_status",
          accessor: (row: any) => ({
            value: row.product_ad_status,
          }),
          Cell: (props: any) => <StatusCell {...props} />,
          isVisible: true,
        },
        ...getPerformanceColumns(
          ENTITY_TYPE.productAd,
          currencyRates,
          homeCurrency,
          currentCurrency,
          t,
          false
        ),
      ],
      [
        currencyRates,
        currentCurrency,
        homeCurrency,
        adGroupId,
        t,
        marketplaceType,
      ]
    );

    const [searchText, setSearchText] = useState("");

    const {
      isFetching: isColumnsFetching,
      myColumns,
      setMyColumns,
      isUpdating: isColumnUpdating,
    } = usePersistentTableColumns({
      tableId: PERSISTENT_TABLE_IDS.MARKETING_PRODUCT_ADS,
      initialColumns: getInitialColumns(),
    });

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchMarketingProductAds(
            userInfo._id,
            mid,
            countryCode,
            marketplaceType,
            store?.marketplaceSubtype,
            searchText,
            reportDateFrom,
            reportDateTo,
            adGroupId,
            sortBy.length > 0
              ? {
                  pageSize,
                  pageIndex,
                  sortKey: sortBy[0].id,
                  sortDesc: sortBy[0].desc,
                }
              : {
                  pageSize,
                  pageIndex,
                  sortKey: "attributed_sales",
                  sortDesc: true,
                }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        userInfo._id,
        mid,
        countryCode,
        marketplaceType,
        searchText,
        reportDateFrom,
        reportDateTo,
        adGroupId,
      ]
    );

    return (
      <Panel
        id="widget-marketing-keyword-summary"
        title={
          isTikTokMarketplace
            ? t("advertisingDashboardWidget.productGroupPerformance.mainTitle")
            : t("advertisingDashboardWidget.productPerformance.mainTitle")
        }
        tooltip={
          isTikTokMarketplace
            ? t("advertisingDashboardWidget.tiktokProductAd.tooltip")
            : undefined
        }
        content={
          <Table
            columns={myColumns}
            data={get(marketing, "productAds.data", [])}
            fetchData={fetchData}
            loading={loading || isColumnsFetching}
            sorting={true}
            pagination={true}
            pageCount={Math.ceil(
              get(marketing, "productAds.count", 0) / PAGE_SIZE
            )}
            pageSize={PAGE_SIZE}
          />
        }
        actions={
          <>
            <SearchFilter setSearchText={setSearchText} />
            <ColumnSelect
              columns={myColumns}
              setColumns={setMyColumns}
              isColumnUpdating={isColumnUpdating}
            />
            <DownloadCsv
              {...{
                mid: store?.merchantId,
                reportType: "productAd",
                path: "/api/amazon/advertising/productAd",
                params: {
                  adGroupId,
                  mid,
                  countryCode,
                  marketplaceType,
                  marketplaceSubtype: store?.marketplaceSubtype,
                  searchText,
                  fromDate: reportDateFrom,
                  toDate: reportDateTo,
                  shopName: store?.storeName,
                  currentCurrency,
                  exchangeRate,
                },
              }}
            />
          </>
        }
      />
    );
  }
);

export default ProductAdSummaryPanel;
