import React, { memo, useState } from "react";
import {
  useReportRunsQuery,
  useReportTypesQuery,
  useScheduledReportsQuery,
} from "~/store/mystore/amc.redux";

import { AMCReportType } from "~/typedef/amc/reportType";
import AMCReportVisualization from "~/modules/amc/amcReportVisualization";
import { AMCScheduledReport } from "~/typedef/amc/scheduledReport";
import { AMCScheduledReportRun } from "~/typedef/amc/scheduledReportRun";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { GenericReportTable } from "~/modules/amc/genericReportTable";
import { GenericSelect, Option } from "~/components/select/genericSelect";
import { Grid } from "@material-ui/core";
import { GridWithErrorBoundary } from "~/modules/errorBoundary/errorBoundary";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const REPORT_TABLE_PAGE_SIZE = 20;

const ViewAmcReport = memo(function ViewAmcReport() {
  const { t } = useTranslation();

  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const [selectedReportType, setSelectedReportType] = useState<
    AMCReportType | undefined
  >();
  const [selectedScheduledReport, setSelectedScheduledReport] = useState<
    AMCScheduledReport | undefined
  >();
  const [currentScheduledReportRun, setCurrentScheduledReportRun] = useState<
    AMCScheduledReportRun | undefined
  >();
  const [priorScheduledReportRun, setPriorScheduledReportRun] = useState<
    AMCScheduledReportRun | undefined
  >();

  const { amcReportTypes, reportTypesLoading } = useReportTypesQuery(
    {},
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          amcReportTypes: data?.reportTypes ?? [],
          reportTypesLoading: isFetching,
        };
      },
    }
  );

  const { scheduledReports } = useScheduledReportsQuery(
    {
      mid: store?.merchantId || "",
      queryType: selectedReportType?._id || "",
    },
    {
      skip: !selectedReportType || !store,
      selectFromResult: ({ data }) => {
        return {
          scheduledReports: data?.scheduledReports ?? [],
        };
      },
    }
  );

  const { scheduledReportRuns } = useReportRunsQuery(
    {
      mid: store?.merchantId || "",
      scheduledReportId: selectedScheduledReport?._id || "",
    },
    {
      skip: !selectedScheduledReport || !store,
      selectFromResult: ({ data }) => {
        return {
          scheduledReportRuns: data?.reportRuns ?? [],
        };
      },
    }
  );

  const updateSelectedReportType = (option: Option) => {
    const reportType = amcReportTypes.find((item) => item._id === option.name);
    if (reportType) {
      setSelectedReportType(reportType);
      setSelectedScheduledReport(undefined);
      setCurrentScheduledReportRun(undefined);
      setPriorScheduledReportRun(undefined);
    }
  };

  const updateSelectedScheduledReport = (option: Option) => {
    const scheduledReport = scheduledReports.find(
      (item) => item._id === option.name
    );
    if (scheduledReport) {
      setSelectedScheduledReport(scheduledReport);
      setCurrentScheduledReportRun(undefined);
      setPriorScheduledReportRun(undefined);
    }
  };

  if (!store || reportTypesLoading) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  return (
    <PageBlock>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GenericSelect
            id="amc-report-type-select"
            title={t("amc.reportTypeTitle")}
            currentOption={
              selectedReportType
                ? {
                    name: selectedReportType._id,
                    label: t(`amc.${selectedReportType.name}.label`),
                  }
                : undefined
            }
            setOption={updateSelectedReportType}
            options={amcReportTypes.map((reportType) => ({
              name: reportType._id,
              label: t(`amc.${reportType.name}.label`),
            }))}
            placeholderLabel={t("amc.selectReportType.label")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericSelect
            id="amc-scheduled-report-select"
            title={t("amc.scheduledReportTitle")}
            currentOption={
              selectedScheduledReport
                ? {
                    name: selectedScheduledReport._id,
                    label: selectedScheduledReport.reportName,
                  }
                : undefined
            }
            setOption={updateSelectedScheduledReport}
            options={scheduledReports.map((report) => ({
              name: report._id,
              label: report.reportName,
            }))}
            placeholderLabel={t("amc.selectScheduledReport.label")}
            disabled={!selectedReportType}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericSelect
            id="amc-scheduled-report-run-current-select"
            title={t("amc.scheduledReportRunTitle")}
            currentOption={
              currentScheduledReportRun
                ? {
                    name: currentScheduledReportRun._id,
                    label: `${moment(
                      currentScheduledReportRun.workflowExecution
                        .timeWindowStart
                    ).format("YYYY-MM-DD")} - ${moment(
                      currentScheduledReportRun.workflowExecution.timeWindowEnd
                    ).format("YYYY-MM-DD")}`,
                  }
                : undefined
            }
            setOption={(option) => {
              const scheduledReportRun = scheduledReportRuns.find(
                (item) => item._id === option.name
              );
              scheduledReportRun &&
                setCurrentScheduledReportRun(scheduledReportRun);
            }}
            options={scheduledReportRuns.map((report) => ({
              name: report._id,
              label: `${moment(report.workflowExecution.timeWindowStart).format(
                "DD/MM/YYYY"
              )} - ${moment(report.workflowExecution.timeWindowEnd).format(
                "DD/MM/YYYY"
              )}`,
            }))}
            placeholderLabel={t("amc.selectScheduledReportRunCurrent.label")}
            disabled={!selectedScheduledReport}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GenericSelect
            id="amc-scheduled-report-run-prior-select"
            title={t("amc.scheduledReportRunTitle")}
            currentOption={
              priorScheduledReportRun
                ? {
                    name: priorScheduledReportRun._id,
                    label: `${moment(
                      priorScheduledReportRun.workflowExecution.timeWindowStart
                    ).format("YYYY-MM-DD")} - ${moment(
                      priorScheduledReportRun.workflowExecution.timeWindowEnd
                    ).format("YYYY-MM-DD")}`,
                  }
                : undefined
            }
            setOption={(option) => {
              const scheduledReportRun = scheduledReportRuns.find(
                (item) => item._id === option.name
              );
              scheduledReportRun &&
                setPriorScheduledReportRun(scheduledReportRun);
            }}
            options={(scheduledReportRuns || []).map((report) => ({
              name: report._id,
              label: `${moment(report.workflowExecution.timeWindowStart).format(
                "DD/MM/YYYY"
              )} - ${moment(report.workflowExecution.timeWindowEnd).format(
                "DD/MM/YYYY"
              )}`,
            }))}
            placeholderLabel={t("amc.selectScheduledReportRunPrior.label")}
            disabled={!currentScheduledReportRun}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {selectedReportType && currentScheduledReportRun && (
              <>
                {selectedReportType.tabular && (
                  <GridWithErrorBoundary item xs={12}>
                    <GenericReportTable
                      store={store}
                      reportType={selectedReportType}
                      currentReportRunId={currentScheduledReportRun._id}
                      priorReportRunId={priorScheduledReportRun?._id}
                      currentCurrency={currentCurrency}
                      pageSize={REPORT_TABLE_PAGE_SIZE}
                    />
                  </GridWithErrorBoundary>
                )}
                {selectedReportType.visualizations && (
                  <AMCReportVisualization
                    store={store}
                    reportTypeName={selectedReportType.name}
                    currentReportRunId={currentScheduledReportRun._id}
                    priorReportRunId={priorScheduledReportRun?._id}
                    visualizations={selectedReportType.visualizations}
                    currentCurrency={currentCurrency}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PageBlock>
  );
});

export default ViewAmcReport;
