import {
  CampaignPaginationParams,
  MarketingCampaign,
  useMarketingCampaignsQuery,
} from "~/store/mystore/marketing.redux";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import {
  ENTITY_TYPE,
  formatAdType,
  getPerformanceColumns,
} from "./commonColumns";
import React, { memo, useCallback, useState } from "react";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";
import { useReportDateFrom, useReportDateTo } from "./useReportDate";

import { BiddingStrategyCell } from "~/components/table/cells/biddingStrategyCell";
import { CustomBadge } from "~/components/toolbars/sideNavigation/drawerMenuItem";
import { DemoTooltip } from "~/components/tooltip/demoTooltip";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkCell } from "~/components/table/cells/linkCell";
import Panel from "~/components/panel/panel";
import SearchFilter from "~/components/adTable/searchFilter";
import SmallButton from "~/components/buttons/smallButton";
import StatusCell from "./statusCell";
import Table from "~/components/adTable/table";
import { TextCell } from "~/components/table/cells/textCell";
import UploadCampaignsModal from "./uploadCampaignsModal";
import get from "lodash/get";
import { hasFilteredSuffix } from "~/utils/marketplaceUtils";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { usePersistentTableColumns } from "~/utils/usePersistentTableColumns";
import { PERSISTENT_TABLE_IDS } from "~/utils/persistentTableIdsEnum";

const PAGE_SIZE = 10;

const StyledButton = styled(SmallButton)`
  margin-right: 1rem;
`;

interface CampaignSummaryPanelProps {
  marketplace: string;
  mid: string;
  countryCode: string;
  groupId?: string | null;
}

const CampaignSummaryPanel = memo<CampaignSummaryPanelProps>(
  function CampaignSummaryPanel({ marketplace, mid, countryCode, groupId }) {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);
    const store = useTypedSelector(
      (state) => state.persistentAppSettings?.setting?.data?.currentStore
    );
    const marketplaceType = store?.marketplace;
    const marketplaceSubtype = get(store, "marketplaceSubtype");
    const homeCurrency = getCurrencyByCountryCode[countryCode];
    const currentCurrency = useTypedSelector(
      (state) => state.persistentAppSettings?.setting?.data?.currentCurrency
    );
    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[store?.marketplaceCountry ?? ""],
      currentCurrency
    );

    const reportDateFrom = useReportDateFrom();
    const reportDateTo = useReportDateTo();

    const getInitialColumns = useCallback(
      () => [
        {
          Header: t("advertisingDashboardWidget.adTable.nameColumn"),
          id: "campaign_name",
          accessor: (row: MarketingCampaign) => ({
            value: row.campaign_name,
            link: {
              pathname: `/mystores/${marketplace}/marketingCampaignAdGroups`,
              search: `?store=${encodeURI(mid)}`,
              state: {
                campaignId: row.campaign_id,
                campaignName: row.campaign_name,
              },
            },
            subtitle: formatAdType(row.ad_type, t),
          }),
          Cell: (props: any) => <LinkCell {...props} />,
          isVisible: true,
          isLocked: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.statusColumn"),
          id: "campaign_status",
          accessor: (row: MarketingCampaign) => ({
            value: row.campaign_status,
          }),
          Cell: (props: any) => <StatusCell {...props} />,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.targetingTypeColumn"),
          id: "targeting_type",
          accessor: (row: MarketingCampaign) => row.targeting_type,
          Cell: (props: any) => <TextCell {...props} />,
          isVisible: true,
        },
        {
          Header: t("advertisingDashboardWidget.adTable.modeColumn"),
          id: "bidding_strategy",
          accessor: (row: MarketingCampaign) => ({
            value: row.bidding_strategy,
          }),
          Cell: (props: any) => <BiddingStrategyCell {...props} />,
          isVisible: true,
        },
        ...getPerformanceColumns(
          ENTITY_TYPE.campaign,
          currencyRates,
          homeCurrency,
          currentCurrency,
          t
        ),
      ],
      [t, marketplace, mid, currencyRates, homeCurrency, currentCurrency]
    );

    const {
      isFetching: isColumnsFetching,
      myColumns,
      setMyColumns,
      isUpdating: isColumnUpdating,
    } = usePersistentTableColumns({
      tableId: PERSISTENT_TABLE_IDS.MARKETING_CAMPAIGNS,
      initialColumns: getInitialColumns(),
    });

    const [searchText, setSearchText] = useState("");
    const [campaignUploadDialogOpen, setCampaignUploadDialogOpen] =
      useState(false);

    const [paginationParams, setPaginationParams] =
      useState<CampaignPaginationParams>({
        pageSize: PAGE_SIZE,
        pageIndex: 0,
        sortKey: "attributed_sales",
        sortDesc: true,
      });

    const { campaigns, campaignCount, loading } = useMarketingCampaignsQuery(
      {
        customerId: userInfo._id,
        mid,
        countryCode,
        marketplaceType: marketplace,
        marketplaceSubtype,
        searchText,
        reportDateFrom,
        reportDateTo,
        ...paginationParams,
        groupId,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          campaigns: data?.data ?? [],
          campaignCount: data?.count ?? 0,
          loading: isFetching,
        }),
      }
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        sortKey: sortBy?.[0]?.id || "attributed_sales",
        sortDesc: sortBy?.[0]?.desc ?? true,
        pageIndex,
        pageSize,
      });
    }, []);

    return (
      <>
        <Panel
          id="widget-marketing-campaign-summary"
          title={t("advertisingDashboardWidget.campaigns.mainTitle")}
          content={
            <Table
              columns={myColumns}
              data={campaigns}
              fetchData={fetchData}
              loading={loading || isColumnsFetching}
              sorting={true}
              pagination={true}
              pageCount={Math.ceil(campaignCount / PAGE_SIZE)}
              pageSize={PAGE_SIZE}
            />
          }
          actions={
            <>
              {store && store.isDemoMode ? (
                <DemoTooltip
                  arrow
                  placement="top"
                  title={t("generic.notAvailableDemoMode")}
                >
                  <span>
                    <StyledButton
                      disabled={true}
                      variant="contained"
                      color="info"
                    >
                      {t("marketingCampaigns.uploadCampaignsButtonLabel")}
                    </StyledButton>
                  </span>
                </DemoTooltip>
              ) : hasFilteredSuffix(store?.marketplace ?? null) ? null : (
                <StyledButton
                  variant="contained"
                  color="info"
                  onClick={() => setCampaignUploadDialogOpen(true)}
                >
                  {t("marketingCampaigns.uploadCampaignsButtonLabel")}
                  <CustomBadge>{t("generic.new")}</CustomBadge>
                </StyledButton>
              )}
              <SearchFilter setSearchText={setSearchText} />
              <ColumnSelect
                columns={myColumns}
                setColumns={setMyColumns}
                isColumnUpdating={isColumnUpdating}
              />
              <DownloadCsv
                {...{
                  mid: store?.merchantId,
                  reportType: "campaign",
                  path: "/api/amazon/advertising/campaign",
                  params: {
                    mid,
                    countryCode,
                    marketplaceType: store?.marketplace,
                    marketplaceSubtype: store?.marketplaceSubtype,
                    searchText,
                    fromDate: reportDateFrom,
                    toDate: reportDateTo,
                    shopName: store?.storeName,
                    currentCurrency,
                    exchangeRate,
                  },
                }}
              />
            </>
          }
        />
        <UploadCampaignsModal
          mid={mid}
          marketplaceType={marketplaceType}
          marketplaceSubtype={marketplaceSubtype}
          countryCode={countryCode}
          openModal={campaignUploadDialogOpen}
          setOpenModal={setCampaignUploadDialogOpen}
        ></UploadCampaignsModal>
      </>
    );
  }
);

export default CampaignSummaryPanel;
