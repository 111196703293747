import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  DATETIME_PERIODS_KEY,
  INTERVAL,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import React, { useCallback, useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  useCreateScheduledReportMutation,
  useReportTypesQuery,
} from "~/store/mystore/amc.redux";

import { AMCReportType } from "~/typedef/amc/reportType";
import ComparisonPeriodSelect from "~/components/select/comparisonPeriodSelect";
import CurrencyDropdown from "~/components/toolbars/toolbarComponents/currencyDropdown";
import { CurrentStore } from "~/typedef/store";
import { DateRange } from "~/typedef/date";
import DateRangeDropdown from "~/components/dateRangePicker/fullDateTimeDropdown";
import { GenericSelect } from "~/components/select/genericSelect";
import InfoAlert from "@components/alert/infoAlert";
import Link from "@components/links/link";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import RaisedButton from "~/components/buttons/raisedButton";
import ReportEmailRecipients from "@pages/reports/reportEmailField";
import SmallButton from "~/components/buttons/smallButton";
import StatusText from "~/components/typography/status";
import TimezoneSelect from "@components/toolbars/toolbarComponents/timezoneDropdown";
import { getScheduledDescription } from "@pages/reports/reportUtils";
import { omit } from "lodash";
import styled from "styled-components";

const ReportFormButton = styled(RaisedButton)`
  ${({ theme }) => `
      ${theme.breakpoints.only("xs")} {
        min-width: 120px;
      }
      ${theme.breakpoints.up("sm")} {
        min-width: 150px;
      }
    `}
`;

const Form = styled.form`
  display: block;
  padding: 1rem;
  max-width: 800px;
`;

interface FrequencyOption {
  name: string;
  label: string;
}

const FREQUENCY_OPTIONS: FrequencyOption[] = [
  { name: "once", label: "createReport.sendOnce" },
  { name: "d", label: "createReport.daily" },
  { name: "w", label: "createReport.weekly" },
  { name: "m", label: "createReport.monthly" },
];

interface ReportingDelayOption {
  name: number;
  label: string;
}

const getReportingDelayOptions = (
  t: TFunction<"translation", undefined>
): ReportingDelayOption[] => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
    switch (num) {
      case 0:
        return { name: num, label: t("createReport.noReportingDelay") };
      case 1:
        return { name: num, label: `${num} ${t("day")}` };
      default:
        return { name: num, label: `${num} ${t("days")}` };
    }
  });
};

const getPeriod = (period: DATETIME_PERIODS): DATETIME_PERIODS => {
  return (Object.values(DATETIME_PERIODS).find((value) => value === period) || [
    "",
  ]) as DATETIME_PERIODS;
};

interface ReportConfigFormProps {
  setQueryType: (type: AMCReportType) => void;
  setFrequency: (freq: string | number) => void;
  setReportingDelay: (reportingDelay: number | undefined) => void;
  setFromDate: (fromDate: number) => void;
  setToDate: (toDate: number) => void;
  setPriorFromDate: (fromDate: number) => void;
  setPriorToDate: (toDate: number) => void;
  setName: (name: string) => void;
  setPeriod: (period: DATETIME_PERIODS) => void;
  setComparisonPeriod: (period: COMPARISON_PERIOD) => void;
  queryType: AMCReportType | null;
  setTimezone: (timezone: string) => void;
  setCurrency: (curr: string) => void;
  currency: string;
  timezone: string;
  period: DATETIME_PERIODS;
  name: string;
  comparisonPeriod: COMPARISON_PERIOD;
  fromDate: number;
  toDate: number;
  priorFromDate: number;
  priorToDate: number;
  frequency: string | number;
  reportingDelay?: number;
  store?: CurrentStore;
  emailsToBeSent: string[];
  setEmailsToBeSent: (emails: string[]) => void;
  description: string;
  setDescription: (emails: string) => void;
}
export const AmcReportConfigForm: React.FC<ReportConfigFormProps> = ({
  setQueryType,
  setFrequency,
  setReportingDelay,
  setFromDate,
  setToDate,
  setPriorFromDate,
  setPriorToDate,
  setName,
  setPeriod,
  setComparisonPeriod,
  queryType,
  setTimezone,
  setCurrency,
  currency,
  timezone,
  period,
  name,
  comparisonPeriod,
  fromDate,
  toDate,
  priorFromDate,
  priorToDate,
  frequency,
  reportingDelay,
  store,
  emailsToBeSent,
  setEmailsToBeSent,
  description,
  setDescription,
}) => {
  const { t } = useTranslation();

  const defaultDateOptions = Object.keys(
    omit(DATETIME_PERIODS, "DAY")
  ) as DATETIME_PERIODS_KEY[];
  const scheduledDateOptions = Object.keys(
    omit(DATETIME_PERIODS, "DAY", "CUSTOM")
  ) as DATETIME_PERIODS_KEY[];
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = useState(
    anchorEl ? anchorEl.offsetWidth : null
  );

  const [dateOptions, setDateOptions] = useState(defaultDateOptions);
  const [extraConfiguration, setExtraConfiguration] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState(false);
  // used to hide/display successs messages after report is saved
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const reportingDelayOptions = getReportingDelayOptions(t);
  const selectedReportingDelay = reportingDelayOptions.find(
    (option) => option.name === reportingDelay
  );

  const selectedFrequencyOption = FREQUENCY_OPTIONS.find(
    (option) => option.name === frequency
  );

  const handleFrequencySelect = (option: FrequencyOption) => {
    const newFreq = option.name;
    setFrequency(newFreq);
    if (newFreq === "once") {
      // Send Once - no delay
      setReportingDelay(undefined);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (frequency === "once") {
      setDateOptions(defaultDateOptions);
    } else {
      setDateOptions(scheduledDateOptions);
    }
  }, [frequency]);

  useEffect(() => {
    if (frequency !== "once") {
      const {
        fromDate: convertedFromDate,
        toDate: convertedToDate,
        priorFromDate: convertedPriorFromDate,
        priorToDate: convertedPriorToDate,
      } = getDatesFromPeriod(period, comparisonPeriod, timezone);
      setFromDate(convertedFromDate);
      setToDate(convertedToDate);
      setPriorFromDate(convertedPriorFromDate);
      setPriorToDate(convertedPriorToDate);
    }
  }, [timezone]);

  const [createScheduledReport, { isLoading, isSuccess: creationSucceeded }] =
    useCreateScheduledReportMutation();

  useEffect(() => {
    setSuccess(creationSucceeded);
  }, [creationSucceeded]);

  const { amcReportTypes, reportTypesLoading } = useReportTypesQuery(
    {},
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          amcReportTypes: data?.reportTypes ?? [],
          reportTypesLoading: isFetching,
        };
      },
    }
  );

  useEffect(() => {
    setQueryType(amcReportTypes[0]);
  }, [amcReportTypes]);

  const handleSave = () => {
    if (store && queryType) {
      createScheduledReport({
        mid: store.merchantId,
        queryType: queryType._id,
        reportName: name,
        reportConfig: {
          frequency,
          currentPeriod: period,
          comparisonPeriod,
          timezone,
          currency,
          reportingDelay,
          fromDate,
          toDate,
        },
        description,
        reportRecipients: emailsToBeSent,
      });
    }
  };

  /** for clearing the success message if the user
   * edits the form again */
  const clearSuccessMessage = () => {
    setSuccess(false);
  };

  const onSetComparisonPeriod = useCallback(
    (comparison: COMPARISON_PERIOD) => {
      const range = getDatesFromPeriod(period, comparison, timezone);
      setDates(range, period, comparison, timezone);
    },
    [period, timezone]
  );

  const setDates = (
    range: DateRange,
    period: DATETIME_PERIODS,
    comparison: COMPARISON_PERIOD,
    timezone: string
  ) => {
    setFromDate(range.fromDate);
    setToDate(range.toDate);
    setPriorFromDate(range.priorFromDate);
    setPriorToDate(range.priorToDate);
    setInterval(range.interval);
    setPeriod(period);
    setTimezone(timezone);
    setComparisonPeriod(comparison);
  };

  return (
    <Grid item xs={12}>
      <>
        <Panel
          id="create-report"
          title={t("panel.createReport")}
          content={
            <Form
              method="POST"
              onSubmit={handleSave}
              onChange={clearSuccessMessage}
            >
              <Grid container spacing={2}>
                {success && (
                  <Grid item xs={12}>
                    <InfoAlert
                      message={t("amc.createScheduledReport.success")}
                      actions={
                        <Link
                          to={`/mystores/amc/reportstatus?store=${
                            store?.merchantId ?? ""
                          }`}
                        >
                          <SmallButton color="primary" variant="contained">
                            {t("amc.createScheduledReport.viewReportStatus")}
                          </SmallButton>
                        </Link>
                      }
                    ></InfoAlert>
                  </Grid>
                )}
                {isLoading || reportTypesLoading || !store ? (
                  <Grid item xs={12}>
                    <PanelLoading />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <GenericSelect
                          id="report-query-select"
                          title={t("createReport.reportType")}
                          currentOption={
                            queryType
                              ? {
                                  name: queryType._id,
                                  label: t(`amc.${queryType.name}.label`),
                                }
                              : undefined
                          }
                          setOption={(option) => {
                            const reportType = amcReportTypes.find(
                              (item) => item._id === option.name
                            );
                            reportType && setQueryType(reportType);
                          }}
                          options={amcReportTypes.map((reportType) => ({
                            name: reportType._id,
                            label: t(`amc.${reportType.name}.label`),
                          }))}
                          placeholderLabel={t("amc.selectReportType.label")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label={t("createReport.formHeader")}
                          placeholder={t("createReport.formHeaderPlaceholder")}
                          required
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <GenericSelect
                          id="report-frequency-select"
                          title={t("createReport.selectFrequencyTitle")}
                          currentOption={
                            selectedFrequencyOption
                              ? {
                                  name: selectedFrequencyOption.name,
                                  label: t(selectedFrequencyOption.label),
                                }
                              : undefined
                          }
                          setOption={handleFrequencySelect}
                          options={FREQUENCY_OPTIONS.map((option) => ({
                            name: option.name,
                            label: t(option.label),
                          }))}
                          placeholderLabel={t(
                            "createReport.selectFrequencyTitle"
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {frequency === "once" ? (
                      <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                          <DateRangeDropdown
                            title={t("createReport.selectDateRangeTitle")}
                            setDates={setDates}
                            period={getPeriod(period)}
                            range={{
                              fromDate,
                              toDate,
                              priorFromDate,
                              priorToDate,
                              timezone,
                              interval: INTERVAL.DAYS,
                            }}
                            compare={comparisonPeriod}
                            timezone={timezone}
                            options={dateOptions}
                            rightAlign={true}
                            useList={false}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth>
                            <DateRangeDropdown
                              title={t("createReport.selectDateRangeTitle")}
                              setDates={setDates}
                              period={getPeriod(period)}
                              range={{
                                fromDate,
                                toDate,
                                priorFromDate,
                                priorToDate,
                                timezone,
                                interval: INTERVAL.DAYS,
                              }}
                              compare={comparisonPeriod}
                              timezone={timezone}
                              options={dateOptions}
                              rightAlign={true}
                              useList={true}
                              minWidth={100}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth>
                            <ComparisonPeriodSelect
                              fullWidth
                              setCompare={onSetComparisonPeriod}
                              currentCompare={comparisonPeriod}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <CurrencyDropdown
                          currentCurrency={currency}
                          switchCurrency={setCurrency}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    {frequency !== "once" && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth>
                            <GenericSelect
                              id="report-delay-select"
                              title={t(
                                "createReport.selectReportingDelayTitle"
                              )}
                              tooltip={t("reportingDelay.tooltip")}
                              currentOption={
                                selectedReportingDelay
                                  ? {
                                      name: selectedReportingDelay.name.toString(),
                                      label: t(selectedReportingDelay.label),
                                    }
                                  : undefined
                              }
                              setOption={(option) =>
                                setReportingDelay(parseInt(option.name))
                              }
                              options={reportingDelayOptions.map((option) => ({
                                name: option.name.toString(),
                                label: option.label,
                              }))}
                              placeholderLabel={t(
                                "createReport.selectReportingDelayTitle"
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth>
                            <TimezoneSelect
                              handleSelect={setTimezone}
                              selectedTimezone={timezone}
                              tooltip={t("createReport.timezoneTooltip")}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <StatusText variant="body2" status="info" noWrap>
                            {getScheduledDescription(
                              frequency,
                              t,
                              timezone,
                              reportingDelay
                            )}
                          </StatusText>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label={t("amc.descriptionInput.label")}
                          margin="normal"
                          multiline={true}
                          minRows={2}
                          required
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <ReportEmailRecipients
                        emailToBeSent={emailsToBeSent}
                        setEmailToBeSent={setEmailsToBeSent}
                        emailError={emailError}
                        setEmailError={setEmailError}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          }
          secondaryContentTitle={
            extraConfiguration ? (
              <Grid item xs={12}>
                <Typography variant="h3" style={{ paddingBottom: "8px" }}>
                  {t("createReport.widgetConfiguration")}
                </Typography>
              </Grid>
            ) : undefined
          }
          secondaryContent={
            extraConfiguration ? (
              <>{/** TODO extra config renderExtraConfiguration() */}</>
            ) : undefined
          }
          footer={
            <Box display="space-evenly" width="100%">
              <ReportFormButton
                disabled={
                  !name ||
                  !queryType ||
                  isLoading ||
                  success ||
                  (extraConfiguration && !selectedConfiguration)
                }
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  t("createReport.generateButton")
                )}
              </ReportFormButton>
            </Box>
          }
        />
      </>
    </Grid>
  );
};
