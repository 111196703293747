import axiosBaseQuery from "~/utils/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export enum TAG_TYPES {
  Forecast = "Forecast",
  FbaInventorySettings = "FbaInventorySettings",
  MarketingCampaigns = "MarketingCampaigns",
  CustomGroups = "CustomGroups",
  ReportTemplate = "ReportTemplate",
  ScheduledReport = "ScheduledReport",
  ProductDirectCosts = "ProductDirectCosts",
  VendorDeductions = "VendorDeductions",
  PersistentTableSettings = "PersistentTableSettings",
}

export const api = createApi({
  baseQuery: axiosBaseQuery({}),
  endpoints: (_build) => ({}),
  tagTypes: Object.keys(TAG_TYPES),
});
