import { api, TAG_TYPES } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface GetPersistentTableSettingsRequest {
  tableId: string;
}

interface UpsertPersistentTableSettingsRequest {
  tableId: string;
  columns: {
    id: string;
    isVisible: boolean;
  }[];
}

export interface UpsertPersistentTableSettingsResponse {
  status: "SUCCESS" | "NO_OP";
}

export interface GetTableSettingsResponse {
  data: {
    userId: string;
    tableId: string;
    columns: {
      id: string;
      isVisible: boolean;
    }[];
    createdAt: string;
    updatedAt: string;
  } | null;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    upsertPersistentTableSettings: build.mutation<
      UpsertPersistentTableSettingsResponse,
      UpsertPersistentTableSettingsRequest
    >({
      query: (body) => ({
        url: `${baseUrl}/api/user-management-service/api/persistentTableSettings`,
        method: "POST",
        data: body,
      }),
      onQueryStarted: globalQueryErrorHandler("UpsertPersistentTableSettings"),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: (result, error, arg) => [
        { type: TAG_TYPES.PersistentTableSettings, id: arg.tableId },
      ],
    }),

    getPersistentTableSettings: build.query<
      GetTableSettingsResponse,
      GetPersistentTableSettingsRequest
    >({
      query: (params) => ({
        url: `${baseUrl}/api/user-management-service/api/persistentTableSettings`,
        method: "GET",
        params,
      }),
      onQueryStarted: globalQueryErrorHandler("GetPersistentTableSettings"),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result, error, arg) => [
        { type: TAG_TYPES.PersistentTableSettings, id: arg.tableId },
      ],
    }),
  }),
});

export const {
  useUpsertPersistentTableSettingsMutation,
  useGetPersistentTableSettingsQuery,
} = extendedApiSlice;
