import { Box, Grid } from "@material-ui/core";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  getExchangeRate,
  intFormatterRounded,
} from "~/utils/currencyUtils";

import ACOSCell from "~/components/table/cells/acosCell";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import NoScrollTable from "~/components/table/table";
import { OutOfStockCell } from "~/components/table/cells/outOfStockCell";
import Panel from "~/components/panel/panel";
import { Range } from "~/typedef/store";
import SearchFilter from "~/components/adTable/searchFilter";
import TACOSCell from "~/components/table/cells/tacosCell";
import Table from "~/components/adTable/table";
import { User } from "~/typedef/user";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { ValueCell } from "~/components/table/cells/valueCell";
import { fetchParentMetrics } from "~/store/parentMetrics.redux";
import { getPercentageDifference } from "~/utils/salesUtils";
import { useDispatch } from "react-redux";
import { usePersistentTableColumns } from "~/utils/usePersistentTableColumns";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";
import { PERSISTENT_TABLE_IDS } from "~/utils/persistentTableIdsEnum";

interface SalesByParentProps {
  conditionalFormatting?: boolean;
  report?: boolean;
  userInfo: User;
  mid: string;
  shopName: string;
  countryCode: string;
  currentPeriod: string;
  currentRange: Range;
  currentCurrency: string;
  includeTax: boolean;
  pageSize: number;
  marketplace: string;
  condensed?: boolean;
}

const SalesByParentWidget = memo(
  ({
    conditionalFormatting,
    report,
    currentCurrency,
    currentPeriod,
    currentRange,
    userInfo,
    mid,
    shopName,
    countryCode,
    includeTax,
    pageSize,
    marketplace,
    condensed,
  }: SalesByParentProps) => {
    const { t } = useTranslation();
    const CONDENSED_ROWS = 5;
    const parentProducts = useTypedSelector(
      (state) => state?.parentProducts?.data || []
    );
    const parentProductsCurrency = useTypedSelector(
      (state) => state?.parentProducts?.currency || ""
    );
    const parentProductsCount = useTypedSelector(
      (state) => state?.parentProducts?.count || 0
    );
    const parentProductsFetching = useTypedSelector(
      (state) => state?.parentProducts?.fetching || false
    );

    const parentProductsParams = useTypedSelector(
      (state) => state?.parentProducts?.params || {}
    );

    const isVendorStore = useVendorStore(mid);
    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[countryCode],
      currentCurrency
    );

    const [searchText, setSearchText] = useState("");

    const dispatch = useDispatch();

    const fetchData = useCallback(
      ({ pageSize, pageIndex, sortBy }) => {
        dispatch(
          fetchParentMetrics(
            {
              user: userInfo,
              mid,
              currentRange,
              searchText,
              sortKey: (sortBy && sortBy[0]?.id) || "totalSales",
              sortOrder:
                sortBy && sortBy[0]?.id
                  ? sortBy[0]?.desc
                    ? "desc"
                    : "asc"
                  : "desc",
              pageIndex: pageIndex || 0,
              pageSize,
              includeTax,
            },
            parentProductsParams
          )
        );
      },
      [userInfo, mid, currentRange, includeTax, searchText]
    );

    useEffect(() => {
      if (mid) {
        fetchData({ pageSize });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mid, userInfo._id]);

    const generateColumns = useCallback(() => {
      return [
        {
          Header: t("parentProducts.ParentColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: ` ${row.asin} | ${row.childProductCount} ${t(
              "trafficAndConversion.skusView"
            )}`,
            image: row.imageUrl,
            link: row.link,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell
              {...props}
              colorVariant="external"
              maxWidth={report ? "" : "25rem"}
            />
          ),
          isVisible: true,
          sticky: "left",
        },
        {
          Header: t(
            `customGroups.${isVendorStore ? "poValueColumn" : "salesColumn"}`
          ),
          accessor: (row: {
            totalSales: number;
            priorTotalSales: number;
            currency: string;
          }) => {
            return {
              value:
                row.totalSales !== undefined
                  ? formatCurrency(
                      row.totalSales,
                      currencyRates,
                      parentProductsCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorTotalSales
                ? getPercentageDifference(row.totalSales, row.priorTotalSales)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "totalSales",
          align: "right",
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: isVendorStore
            ? t("purchaseOrder.unitsColumn")
            : t("customGroups.unitsSoldColumn"),
          accessor: (row: { unitsSold: number; priorUnitsSold: number }) => {
            return {
              value: row.unitsSold !== undefined ? row.unitsSold : "-",
              growth: row.priorUnitsSold
                ? getPercentageDifference(row.unitsSold, row.priorUnitsSold)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "unitsSold",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customGroups.avgPriceColumn"),
          accessor: (row: {
            avPrice: number;
            priorAvPrice: number;
            currency: string;
          }) => {
            return {
              value:
                row.avPrice !== undefined
                  ? formatCurrency(
                      row.avPrice,
                      currencyRates,
                      parentProductsCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorAvPrice
                ? getPercentageDifference(row.avPrice, row.priorAvPrice)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "avPrice",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        ...(marketplace.includes("amazon") && !report
          ? [
              {
                Header: t("customGroups.adSpendColumn"),
                id: "adSpend",
                disableSortBy: true,
                accessor: (row: {
                  adSpend: number;
                  countryCode: string;
                  currency: string;
                }) => {
                  return row.adSpend
                    ? formatCurrency(
                        row.adSpend,
                        currencyRates,
                        parentProductsCurrency,
                        currentCurrency
                      )
                    : "-";
                },
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },
              {
                Header: t("customGroups.impressionsColumn"),
                id: "impressions",
                disableSortBy: true,
                accessor: (row: { impressions: number }) =>
                  row.impressions
                    ? intFormatterRounded.format(row.impressions)
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },

              {
                Header: t("customGroups.clickThroughRateColumn"),
                id: "clickThroughRate",
                disableSortBy: true,
                accessor: (row: { clickThroughRate: number }) =>
                  row.clickThroughRate
                    ? `${row.clickThroughRate.toFixed(2)}%`
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },
              {
                Header: t("customGroups.conversionRateColumn"),
                id: "conversionRate",
                disableSortBy: true,
                accessor: (row: { conversionRate: number }) =>
                  row.conversionRate
                    ? `${row.conversionRate.toFixed(2)}%`
                    : "-",
                align: "right",
                Cell: ValueCell,
                isVisible: true,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.clicksColumn"),
                id: "clicks",
                disableSortBy: true,
                accessor: (row: any) =>
                  row.clicks ? intFormatterRounded.format(row.clicks) : "-",
                align: "right",
                Cell: ValueCell,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.salesColumn"),
                id: "adSales",
                disableSortBy: true,
                accessor: (row: any) =>
                  row.adSales
                    ? formatCurrency(
                        row.adSales,
                        currencyRates,
                        parentProductsCurrency,
                        currentCurrency
                      )
                    : "-",
                align: "right",
                Cell: ValueCell,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.acosColumn"),
                id: "acos",
                disableSortBy: true,
                accessor: (row: any) => {
                  return {
                    value: row.acos ? `${row.acos.toFixed(1)}%` : "-",
                  };
                },
                align: "right",
                Cell: ACOSCell,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.adUnitsColumn"),
                id: "adUnits",
                disableSortBy: true,
                accessor: (row: any) =>
                  row.adUnits ? intFormatterRounded.format(row.adUnits) : "-",
                align: "right",
                Cell: ValueCell,
              },
              {
                Header: t("advertisingDashboardWidget.adTable.tacosColumn"),
                id: "tacos",
                disableSortBy: true,
                accessor: (row: any) => {
                  return {
                    value: row.tacos ? `${row.tacos.toFixed(1)}%` : "-",
                  };
                },
                align: "right",
                Cell: TACOSCell,
              },
            ]
          : []),
        ...(marketplace !== "amazon_vendor"
          ? [
              {
                id: "outOfStockPercentage",
                Header: t("customGroups.outOfStockPercentageColumn"),
                accessor: (row: any) => {
                  return {
                    outOfStockPercentage: row.outOfStockPercentage,
                    conditionalFormatting,
                    align: "center",
                  };
                },
                Cell: OutOfStockCell,
                align: "center",
                isVisible: true,
              },
            ]
          : []),
      ];
    }, [
      mid,
      currentRange,
      currentPeriod,
      currentCurrency,
      parentProductsCurrency,
      t,
      isVendorStore,
      report,
      conditionalFormatting,
      currencyRates,
    ]);

    const {
      isFetching: isColumnsFetching,
      myColumns,
      setMyColumns,
      isUpdating: isColumnUpdating,
    } = usePersistentTableColumns({
      tableId: PERSISTENT_TABLE_IDS.SALES_BY_PARENT,
      initialColumns: generateColumns(),
    });

    const condensedColumns: Column[] = useMemo(
      () => [
        {
          Header: t("parentProducts.ParentColumn"),
          id: "title",
          accessor: (row: any) => ({
            value: row.title,
            secondRowValue: ` ${row.asin} | ${row.childProductCount} ${t(
              "trafficAndConversion.skusView"
            )}`,
            image: row.imageUrl,
            link: row.link,
            target: "_blank",
          }),
          Cell: (props: any) => (
            <LinkAndImageCell
              {...props}
              colorVariant="external"
              maxWidth="25rem"
            />
          ),
          isVisible: true,
          sticky: "left",
          colSpan: 2,
        },
        {
          Header: t(
            `customGroups.${isVendorStore ? "poValueColumn" : "salesColumn"}`
          ),
          accessor: (row: {
            totalSales: number;
            priorTotalSales: number;
            currency: string;
          }) => {
            return {
              value:
                row.totalSales !== undefined
                  ? formatCurrency(
                      row.totalSales,
                      currencyRates,
                      parentProductsCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorTotalSales
                ? getPercentageDifference(row.totalSales, row.priorTotalSales)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "totalSales",
          align: "right",
          Cell: ValueAndGrowthCell,
          isVisible: true,
        },
        {
          Header: isVendorStore
            ? t("purchaseOrder.unitsColumn")
            : t("customGroups.unitsSoldColumn"),
          accessor: (row: { unitsSold: number; priorUnitsSold: number }) => {
            return {
              value: row.unitsSold !== undefined ? row.unitsSold : "-",
              growth: row.priorUnitsSold
                ? getPercentageDifference(row.unitsSold, row.priorUnitsSold)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "unitsSold",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("customGroups.avgPriceColumn"),
          accessor: (row: {
            avPrice: number;
            priorAvPrice: number;
            currency: string;
          }) => {
            return {
              value:
                row.avPrice !== undefined
                  ? formatCurrency(
                      row.avPrice,
                      currencyRates,
                      parentProductsCurrency,
                      currentCurrency
                    )
                  : "-",
              growth: row.priorAvPrice
                ? getPercentageDifference(row.avPrice, row.priorAvPrice)
                : "N/A",
              conditionalFormatting,
            };
          },
          id: "avPrice",
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
          hiddenDown: "md",
        },
      ],
      [
        mid,
        currentRange,
        currentPeriod,
        currentCurrency,
        parentProductsCurrency,
      ]
    );

    return (
      <Grid xs={12}>
        <Panel
          id="widget-parent-products-table"
          title={t(`parentProducts.title`)}
          content={
            condensed ? (
              <NoScrollTable
                {...{
                  columns: condensedColumns,
                  data: parentProducts,
                  pageSize: pageSize ?? CONDENSED_ROWS,
                  // gridLayoutColumns: 6,
                  loading: parentProductsFetching,
                }}
              />
            ) : (
              <Table
                {...{
                  columns: myColumns,
                  data: parentProducts,
                  fetchData: fetchData,
                  pagination: !condensed,
                  sorting: true,
                  pageSize: pageSize,
                  pageCount: Math.ceil((parentProductsCount || 0) / pageSize),
                  loading: parentProductsFetching || isColumnsFetching,
                }}
              />
            )
          }
          actions={
            report ? undefined : (
              <>
                <SearchFilter setSearchText={setSearchText} />
                <Box pl={2}>
                  <ColumnSelect
                    columns={myColumns}
                    setColumns={setMyColumns}
                    isColumnUpdating={isColumnUpdating}
                  />
                </Box>
                <Box pl={1}>
                  <DownloadCsv
                    {...{
                      mid,
                      reportType: "parentMetrics",
                      path: "/api/generic/parentMetrics",
                      params: {
                        ...currentRange,
                        includeTax,
                        shopName: shopName,
                        marketplaceName: marketplace,
                        countryCode: countryCode,
                        currentCurrency,
                        exchangeRate,
                        sortKey: "asin",
                      },
                    }}
                  />
                </Box>
              </>
            )
          }
        />
      </Grid>
    );
  }
);

export default SalesByParentWidget;
